import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "sz-layout-header-menu" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "sz-username" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_menu_outlined = _resolveComponent("menu-outlined")
  const _component_menu_fold_outlined = _resolveComponent("menu-fold-outlined")
  const _component_a_menu_item = _resolveComponent("a-menu-item")
  const _component_a_menu = _resolveComponent("a-menu")
  const _component_expand_outlined = _resolveComponent("expand-outlined")
  const _component_compress_outlined = _resolveComponent("compress-outlined")
  const _component_user_outlined = _resolveComponent("user-outlined")
  const _component_a_popover = _resolveComponent("a-popover")
  const _component_a_skeleton = _resolveComponent("a-skeleton")
  const _component_a_layout_header = _resolveComponent("a-layout-header")
  const _component_PasswordModal = _resolveComponent("PasswordModal")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_layout_header, { class: "header sz-layout-header" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_skeleton, {
          active: "",
          loading: _ctx.loading,
          paragraph: { rows: 0 }
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("img", {
                class: "logo",
                src: _ctx.logo,
                alt: ""
              }, null, 8, _hoisted_2),
              _withDirectives(_createVNode(_component_menu_outlined, {
                class: "trigger sz-layout-header-trigger",
                onClick: _ctx.triggerLayoutSideBar
              }, null, 8, ["onClick"]), [
                [_vShow, _ctx.sideBar]
              ]),
              _withDirectives(_createVNode(_component_menu_fold_outlined, {
                class: "trigger sz-layout-header-trigger",
                onClick: _ctx.triggerLayoutSideBar
              }, null, 8, ["onClick"]), [
                [_vShow, !_ctx.sideBar]
              ]),
              _createVNode(_component_a_menu, {
                selectedKeys: _ctx.selectedKeys,
                mode: "horizontal",
                style: { lineHeight: '50px', backgroundColor: '#0A66B0', borderBottomColor: '#0A66B0' }
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nav, (item) => {
                    return (_openBlock(), _createBlock(_component_a_menu_item, {
                      key: item.key
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.title), 1)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }, 8, ["selectedKeys"])
            ]),
            _createElementVNode("div", null, [
              _withDirectives(_createVNode(_component_expand_outlined, {
                onClick: _ctx.handleFullScreen,
                class: "sz-header-operation"
              }, null, 8, ["onClick"]), [
                [_vShow, !_ctx.isFullscreen]
              ]),
              _withDirectives(_createVNode(_component_compress_outlined, {
                onClick: _ctx.handleFullScreen,
                class: "sz-header-operation"
              }, null, 8, ["onClick"]), [
                [_vShow, _ctx.isFullscreen]
              ]),
              _createVNode(_component_a_popover, { title: "操作" }, {
                content: _withCtx(() => [
                  _createElementVNode("p", {
                    class: "log-out",
                    onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.onLogOut && _ctx.onLogOut(...args)))
                  }, "退出登录")
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_user_outlined, { class: "sz-header-operation" })
                ]),
                _: 1
              }),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.realname), 1)
            ])
          ]),
          _: 1
        }, 8, ["loading"])
      ]),
      _: 1
    }),
    (_ctx.isShowModal)
      ? (_openBlock(), _createBlock(_component_PasswordModal, {
          key: 0,
          onOnCloseModal: _ctx.onCloseModal,
          onOnSuccess: _ctx.onSuccess
        }, null, 8, ["onOnCloseModal", "onOnSuccess"]))
      : _createCommentVNode("", true)
  ], 64))
}