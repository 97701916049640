import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"calc(100% - 50px)","display":"flex","flex-direction":"row","overflow-y":"hidden"} }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_layout_header = _resolveComponent("layout-header")
  const _component_layout_side_bar = _resolveComponent("layout-side-bar")
  const _component_layout_tabs = _resolveComponent("layout-tabs")
  const _component_layout_content = _resolveComponent("layout-content")
  const _component_a_layout = _resolveComponent("a-layout")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_layout_header),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_layout_side_bar),
      _createVNode(_component_a_layout, {
        class: "sz-layout-content",
        style: {"border-left":"12px solid #F3F3F3","background-color":"#FFF","width":"60%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_layout_tabs),
          _createVNode(_component_layout_content)
        ]),
        _: 1
      })
    ])
  ], 64))
}