import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_a_config_provider = _resolveComponent("a-config-provider")

  return (_openBlock(), _createBlock(_component_a_config_provider, { locale: _ctx.zh_CN }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component)))
    ]),
    _: 1
  }, 8, ["locale"]))
}