import 'core-js/stable'
import 'regenerator-runtime/runtime'
import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import store from './store/index.js'
import router from './router/index.js'
import axios from 'axios'
import VueAxios from 'vue-axios'
import antdMixin from './mixin/antd.js'
import 'ant-design-vue/dist/antd.css'

const app = createApp(App).use(Antd).use(store).use(router).use(VueAxios, axios).mixin(antdMixin)
app.mount("#app");
