import axios from "axios";
import qs from "qs";
let localhost = window.location.href.indexOf('localhost')
//let isTy = window.location.href.indexOf('localhost')
//let isTy = window.location.href.indexOf('crm.tenant')
// 测试服
//let isTy = window.location.href.indexOf('tyjx')
let BASE_URL;
if ( window.location.href.indexOf('localhost') !== -1 ) {
	// let str = 'http://txmfj.crm.tenant.weka.cc/'
	// if (str.indexOf('crm.tenant') !== -1){
	// 	//BASE_URL = 'http://'+code+'.crmadmin.tenant.weka.cc/admin'
	// 	let newstr = str.split(".")[0].split("//")
	// 	let code = newstr[newstr.length-1]
	// 	BASE_URL = 'http://'+code+'.crmadmin.tenant.weka.cc/admin'
	// }else{
	// 	BASE_URL = 'http://tycrm.sdszkj.cn/admin'
	// }
	// let str = "http://4mbx5.crm.tenant.weka.cc/"
    // let newstr = str.split(".")[0].split("//")
	// let code = newstr[newstr.length-1]
	// BASE_URL = 'http://'+code+'.crmadmin.tenant.weka.cc/admin'
	//BASE_URL = 'http://tycrm.sdszkj.cn/admin'
	BASE_URL = 'http://crmtyadmin.weka.cc/admin'
} else if (window.location.href.indexOf('tyjx') !== -1) {
	console.log(456)
	BASE_URL = 'http://tycrm.sdszkj.cn/admin'
} else if (window.location.href.indexOf('zycrm') !== -1){
	console.log(789)
	BASE_URL = 'http://zycrmadmin.weka.cc/admin' 
} else if (window.location.href.indexOf('zrwh') !== -1){
	console.log(123)
	BASE_URL = 'http://zrwhadmin.weka.cc/admin'
}else if (window.location.href.indexOf('crm.tenant') !== -1){
	console.log(222)
	//BASE_URL = 'http://'+code+'.crmadmin.tenant.weka.cc/admin'
	let str = window.location.href
    let newstr = str.split(".")[0].split("//")
	let code = newstr[newstr.length-1]
	BASE_URL = 'http://'+code+'.crmadmin.tenant.weka.cc/admin'
}else if (window.location.href.indexOf('crmty') !== -1){
	console.log(333)
	isTy = window.location.href.indexOf('crmty')
	BASE_URL = localhost !== -1 ? 'https://zycrmadmin.weka.cc/admin' : isTy === -1 ?  'https://crmzyadmin.weka.cc/admin' : 'https://crmtyadmin.weka.cc/admin'
}
// else{
// 	BASE_URL = 'https://crmzyadmin.weka.cc/admin';
// }
  

// 正式服
//let localhost = window.location.href.indexOf('crm.tianyunzh')
let isTy = window.location.href.indexOf('tianyunzh')
console.log(window.location.href.indexOf('crm.tianyunzh'))
console.log(window.location.href.indexOf('tianyunzh'))
//BASE_URL = localhost !== -1 ? 'https://zycrmadmin.weka.cc/admin' : isTy === -1 ?  'https://crmzyadmin.weka.cc/admin' : 'https://crmtyadmin.weka.cc/admin'
BASE_URL = 'https://crmtyadmin.weka.cc/admin'
document.title = localhost !== -1 ? '天意机械CRM系统' : isTy === -1 ? '卓越铝材CRM系统' : '天意机械CRM系统'

const APP_ID = 466
// const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJqbnN6a2oiLCJpYXQiOjE2NDczMDQ3NTgsImV4cCI6MTY0ODYwMDc1OCwibmJmIjoxNjQ3MzA0NzU4LCJzdWIiOiIiLCJqdGkiOiI1ZGE5ZDRhYTM0ZGFiNThhMmE1MTA0YjJmNzY5ZWNhMCIsImlkIjoxMTc1NzMsImFwcF9pZCI6NDY2LCJwaG9uZSI6IiIsInVzZXJuYW1lIjoieGlhb3Nob3UwMSIsInJlYWxuYW1lIjoi6ZSA5ZSuMDEiLCJyb2xlX2lkIjoxNjkyLCJvcmdfaWQiOjI1MDQsIm5vdGUiOm51bGwsImF2YXRhciI6bnVsbH0.qPADxE4mMWCjEent6FMqsIniuoECHc6zJkosisSmFeQ'
/**
 * 封装请求方式
 */
const request =
{
	/**
	 * @name: 封装axios get方法
	 * @desc: 描述
	 * @param url 请求连接
	 * @param params 请求参数
	 * @param callback 回调方法
	 */
	get (url, params, callback) {
		return new Promise((resolve, reject) => {
			axios
				.get(url, {
					params: params
				})
				.then(res => {
					callback ? resolve(callback(res.data)) : resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	},
	/**
	 * @name: 封装axios post方法
	 * @desc: 描述
	 * @param url 请求连接
	 * @param params 请求参数
	 * @param callback 回调方法
	 */
	post (url, params, callback) {
		params.app_id = APP_ID
		const is_oa = localStorage.getItem('is_oa')
		const oa_token = localStorage.getItem('oa_token')
		const token = is_oa == '1' ? oa_token : localStorage.getItem('token')
		//console.log(is_oa)
		//console.log(oa_token)
		//console.log(localStorage.getItem('token'))
		//console.log(token)
		return new Promise((resolve, reject) => {
			axios
				.post(BASE_URL + url, qs.stringify(params),{ headers: {'Authorization': token }})
				.then(res => {
					callback ? resolve(callback(res.data)) : resolve(res.data);
				})
				.catch(err => {
					alert('请求超时，请刷新后重试')
					reject(err);
				});
		});
	},
	/**
	 * @name: 封装axios post方法
	 * @desc: 描述
	 * @param url 请求连接
	 * @param params 请求参数
	 * @param callback 回调方法
	 */
	upload (url, params, callback) {
		params.app_id = APP_ID
		const token = localStorage.getItem('token')
		return new Promise((resolve, reject) => {
			axios
				.post(BASE_URL + url, params,{ headers: {'Authorization': token, 'Content-Type':'multipart/form-data'  }})
				.then(res => {
					callback ? resolve(callback(res.data)) : resolve(res.data);
				})
				.catch(err => {
					alert('请求超时，请刷新后重试')
					reject(err);
				});
		});
	},
	download(url, params) {
		// const token = localStorage.getItem('token')
		axios
			.post(
				BASE_URL + url,
				qs.stringify(params),
				{
					responseType: "blob", // 1.首先设置responseType对象格式为 blob:
					// headers: {'Authorization': token }
				}
			)
			.then((res) => {
				console.log(res); //把response打出来，看下图
				let blob = new Blob([res.data], {
					type: "application/vnd.ms-excel",
				});
				// 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
				let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象

				// 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
				let a = document.createElement("a");
				a.href = url;
				a.download = "导出文件.xlsx";
				a.click();
				// 5.释放这个临时的对象url
				window.URL.revokeObjectURL(url);
				this.diaShow = !this.diaShow;
			})
			.catch(() => {
				// this.$message(error.message);
			});
 },
	/**
	 * @name: put请求封装
	 * @author: camellia
	 * @email: guanchao_gc@qq.com
	 * @date: 2021-03-01 
	 * @param url 请求连接
	 * @param params 请求参数
	 * @param callback 回调方法
	 */
	put (url, params, callback) {
		return new Promise((resolve, reject) => {
			axios
				.put(url, params)
				.then(res => {
					callback ? resolve(callback(res.data)) : resolve(res.data);
				}, err => {
					reject(err)
				})
		})
	},
	/**
	 * @name: 请求失败后的错误统一处理
	 * @param {Number} status 请求失败的状态码
	 */
	errorHandle(status, other) {
		// 状态码判断
		switch (status) {
			// 401: 未登录状态，跳转登录页
			case 401:
				// toLogin();
				break;
			// 403 token过期
			// 清除token并跳转登录页
			case 403:
				// tip('登录过期，请重新登录');
				// localStorage.removeItem('token');
				// store.commit('loginSuccess', null);
				setTimeout(() => {
						// toLogin();
				}, 1000);
				break;
			// 404请求不存在
			case 404:
				// tip('请求的资源不存在');
				break;
			default:
				console.log(other);
		}
	}
}
export default request;
