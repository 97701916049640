import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "sz-tabs-tab-box" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")
  const _component_a_tabs = _resolveComponent("a-tabs")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.tabs.length)
      ? (_openBlock(), _createBlock(_component_a_tabs, {
          key: 0,
          activeKey: _ctx.tabActive,
          "onUpdate:activeKey": _cache[0] || (_cache[0] = $event => ((_ctx.tabActive) = $event)),
          "hide-add": "",
          type: "editable-card",
          onEdit: _ctx.onTabEdit,
          onChange: _ctx.onTabChange
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (pane) => {
              return (_openBlock(), _createBlock(_component_a_tab_pane, {
                key: pane.index,
                tab: pane.title,
                closable: pane.closable
              }, null, 8, ["tab", "closable"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["activeKey", "onEdit", "onChange"]))
      : _createCommentVNode("", true)
  ]))
}